import api from '../index'

const login = {
    // 登录
    login(data) {
        return api({
            url: 'v2/ofs/login',
            method: 'post',
            data: data
        })
    },
    ofsLogin(data) {
        return api({
            url: 'v2/wx/ofs-reset-login',
            method: 'post',
            data: data
        })
    },
}
export default login
