<!-- 重置登录页 -->
<template>
  <div class="page-main">
    <div class="login-loading">
      <van-loading type="spinner" vertical>
        加载中...
      </van-loading>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import login from "@/api/ofs/login"
import { wxRedirect } from "@/utils/wxUtils"

export default {
  name: "reset",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
      login.ofsLogin({}).then(res=>{
        if (res.code == 0) {
          // wxRedirect(res.data)
        }
      })
    });

    const data = reactive({
    });

    return {
      ...toRefs(data)
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  .login-loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
